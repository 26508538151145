import { graphql } from 'gatsby';
import React from 'react';
import { AgbView } from '../views/AgbView/AgbView';

export default function AgbPage() {
  return <AgbView />;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
