import { useTranslation } from 'react-i18next';
import { Seo } from '../../components/shared/Seo';
import { Layout } from '../../layout';
import { styled } from '../../../stitches.config';
import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useTranslationsObject } from '../../hooks/useTranslationsObject';
import { agbPageTranslations } from '../../translationObjects/agb';
import { useAppSelector } from '../../hooks/ReduxHooks';

export const AgbView = () => {
  const { t } = useTranslation();
  const { language, defaultLanguage } = useI18next();
  const { email, primaryNumber, realAddress } = useAppSelector(
    (state) => state.contactInfo
  );
  const content = useTranslationsObject(
    agbPageTranslations,
    language,
    defaultLanguage
  );

  return (
    <Layout>
      <Seo title={t('agb.page')}>
        <meta name="robots" content="noindex" />
      </Seo>
      <BgContainer>
        <Container>
          <PageTitle>{t('agb.title')}</PageTitle>
        </Container>
      </BgContainer>
      <InfoBox>
        <ReferencesContainer>
          <Text css={{ marginBottom: 12 }} bold={true}>
            taschner.biz GmbH
          </Text>
          <Text>
            <Text dangerouslySetInnerHTML={{ __html: realAddress }}></Text>
          </Text>
          <br />
          <Text bold={true}>
            Tel.: <span>{primaryNumber}</span>
          </Text>
          <Text bold={true}>
            E-Mail: <span>{email}</span>
          </Text>
        </ReferencesContainer>
        {content}
      </InfoBox>
    </Layout>
  );
};

const BgContainer = styled('div', {
  background: '$primaryBackground',
});

const Container = styled('div', {
  height: 180,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 16px',
  maxWidth: 1280,
  margin: '0 auto',
  '@md': {
    justifyContent: 'start',
    padding: 0,
  },
});

const PageTitle = styled('h1', {
  margin: 0,
  color: '$heading',
  fontWeight: 700,
  fontSize: '36px',
  lineHeight: '44px',
  '@md': {
    fontSize: '48px',
    lineHeight: '56px',
  },
});

const InfoBox = styled('div', {
  margin: '48px 16px 80px',
  maxWidth: 1280,
  '@md': {
    margin: '48px auto 80px',
  },
});

const Text = styled('p', {
  margin: 0,
  lineHeight: '1.5rem',
  color: '$text',
  wordWrap: 'break-word',
  whiteSpace: 'pre-line',
  fontWeight: 500,
  '@md': {
    width: 920,
  },
  variants: {
    bold: {
      true: {
        color: '$text',
        fontWeight: 800,
        '& > span': {
          fontWeight: '500',
        },
      },
    },
  },
});

const ReferencesContainer = styled('div', {
  marginBottom: '24px',
});
