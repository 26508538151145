import React from 'react';
import { styled } from '../../stitches.config';
import { TranslationsObject } from '../hooks/useTranslationsObject';

const Text = styled('p', {
  color: '$text',
  wordWrap: 'break-word',
  '@md': {
    width: 810,
  },
});

const Headings = styled('h2', {
  color: '$text',
  margin: 0,
  '@md': {
    width: 810,
  },
});

const Block = styled('div', {
  margin: '0 0 32px',
});

const Stamp = styled('p', {
  fontWeight: 400,
  color: '$text',
  display: 'inline-block',
  textAlign: 'right',
  width: '100%',
  '@md': {
    width: 810,
  },
});

export const agbPageTranslations: TranslationsObject = {
  de: (
    <>
      <Block>
        <Headings>1. Geltungsbereich</Headings>
        <Text>
          (1) Lieferungen und Leistungen der taschner.biz GmbH (nachfolgend
          TBIZ) erfolgen ausschließlich zu den nachstehenden
          Geschäftsbedingungen und den Bestimmungen in der jeweils gültigen
          Preisliste, soweit nicht schriftlich etwas Gegenteiliges vereinbart
          ist.
          <br />
          (2) Allgemeine Geschäftsbedingungen des Kunden finden selbst dann
          keine Anwendung, wenn der Kunde im Zusammenhang mit seiner Bestellung
          oder Beauftragung auf diese hinweist und TBIZ diesen nicht
          widerspricht. Zusagen, Nebenabreden sowie Änderungen oder Ergänzungen
          der Allgemeinen Geschäftsbedingungen bedürfen der Schriftform. Das
          gilt auch für die Änderung des Schriftformerfordernisses.
        </Text>
      </Block>
      <Block>
        <Headings>2. Lieferungen und Leistungen</Headings>
        <Text>
          (1) Angebote von TBIZ sind freibleibend und unverbindlich. Ein Vertrag
          kommt erst mit Auftragsbestätigung von TBIZ, spätestens mit der
          Annahme der Lieferung und/oder der Leistung durch den Kunden zustande.
          <br />
          (2) Inhalt und Umfang der von TBIZ geschuldeten Leistungen ergeben
          sich ohne andere schriftliche Vereinbarungen aus der
          Auftragsbestätigung von TBIZ.
          <br />
          (3) Zumutbare Teillieferungen und/oder Teilleistungen sind zulässig
          und können gesondert in Rechnung gestellt werden.
          <br /> (4) TBIZ behält sich Produktänderungen, insbesondere im Zuge
          von Weiterentwicklungen vor, sofern die vereinbarten Leistungsdaten
          erreicht werden und für den Kunden zumutbar sind. <br />
          (5) Liefertermine sind unverbindlich, es sei denn, sie werden
          ausdrücklich schriftlich als verbindlich vereinbart. TBIZ kommt in
          jedem Fall nur dann in Verzug, wenn die Verzögerung von TBIZ
          verschuldet ist, die Leistung fällig ist und der Kunde TBIZ erfolglos
          eine angemessene, schriftliche Nachfrist von mindestens 14 Tagen
          gesetzt hat.
          <br />
          (6) Liefertermine verlängern sich für TBIZ angemessen bei Störungen
          aufgrund höherer Gewalt und anderer von TBIZ nicht zu vertretenden
          Ereignissen, wie etwa Störungen bei der Selbstbelieferung durch
          Lieferanten, Streiks, Aussperrungen, Betriebsstörungen, etc. TBIZ
          behält sich das Recht vor, vom Vertrag zurückzutreten, wenn die durch
          derartige Ereignisse hervorgerufene Leistungsverzögerung länger als
          sechs Wochen andauert.
          <br />
          (7) Der Kunde hat gelieferte Ware unverzüglich nach Erhalt auf
          Vollständigkeit, Übereinstimmung mit den Lieferpapieren und
          Mangelhaftigkeit zu überprüfen. Unterbleibt eine schriftliche Rüge
          innerhalb von vier Tagen ab Lieferscheindatum, gilt die Ware als
          ordnungsgemäß und vollständig geliefert, es sei denn, dass es sich um
          einen Mangel handelt, der bei der Untersuchung nicht erkennbar war.
        </Text>
      </Block>
      <Block>
        <Headings>3. Prüfung und Gefahrenübergang</Headings>
        <Text>
          (1) Die Gefahr einer Beschädigung oder eines Verlustes des
          Vertragsprodukts geht mit der Übergabe an den Kunden oder ein
          Transportunternehmen auf den Kunden über.
        </Text>
      </Block>
      <Block>
        <Headings>4. Pflichten des Kunden bei Support</Headings>
        <Text>
          (1) Der Kunde hat TBIZ, soweit dies notwendig ist, alle Zugangsdaten
          für das zu wartende System zur Verfügung zu stellen. Diese Daten sind
          entsprechend der Angaben im Bestellschein oder Angebot anzugeben.
          <br />
          (2) Der Kunde muss vor dem Beginn von Wartungsarbeiten durch TBIZ eine
          Datensicherung vornehmen, da ihm bekannt ist, dass aufgrund der
          Wartungsarbeiten mit einem Datenverlust gerechnet werden muss. TBIZ
          wird selbstständig keine Datensicherung über eine Fernleitung/ das
          Internet vornehmen.
        </Text>
      </Block>
      <Block>
        <Headings>5. Supportanfrage</Headings>
        <Text>
          (1) Der Kunde kann aufgrund des abgeschlossenen Supportvertrags eine
          Anfrage an TBIZ per E-Mail an support@taschner.biz stellen. Andere
          Kommunikationswege sind nach diesen Bedingungen nicht vorgesehen und
          fallen nicht unter die Leistung eines TBIZ Supportvertrags,
          insbesondere die vereinbarten Reaktionszeiten, Zeitfenster oder
          Entgelte. Ausnahmen hiervon sind explizit im Angebot aufgeführt.
        </Text>
      </Block>
      <Block>
        <Headings>6. Leistungsumfang bei Supportverträgen</Headings>
        <Text>
          (1) Aufgrund eines Supportvertrags ist TBIZ verpflichtet, auf eine
          Supportanfrage des Kunden innerhalb der vertraglich vereinbarten
          Reaktionszeit, telefonisch oder per E-Mail zu reagieren und diese zu
          bearbeiten ("Leistung"). Der Umfang der von TBIZ im Einzelnen zu
          erbringenden Leistung ist nachfolgend abschließend geregelt.
          <br />
          (2) Supportanfragen sind grundsätzlich per E-Mail an
          support@taschner.biz zu richten. Ausnahmen davon sind explizit im
          Angebot aufgeführt.
          <br />
          (3) Nach dem Absenden einer Supportanfrage per E-Mail erhält der Kunde
          per E-Mail eine Eingangsbestätigung mit der Ticketnummer an die in der
          Anfragemail angegebene Absenderadresse.
          <br />
          (4) Innerhalb der vereinbarten Reaktionszeit wird TBIZ den Kunden
          unter der angegebenen E-Mailadresse kontaktieren, um die Aufnahme der
          Bearbeitung zu bestätigen, weitere supportrelevante Angaben einzuholen
          oder einen Lösungsvorschlag zu unterbreiten.
          <br />
          (5) Eine Garantie oder eine Verantwortung hinsichtlich der Lösbarkeit
          einer Problemstellung wird nicht gegeben.
          <br />
          (6) Die Mitteilung einer Lösung oder die Mitteilung, dass eine
          Supportanfrage nicht lösbar ist, muss nicht innerhalb der vertraglich
          vereinbarten Reaktionszeit erfolgen. Der Kunde hat keinen Anspruch auf
          die Einhaltung einer Lösungs- oder Wiederherstellungszeit.
          <br />
          (7) Die Leistung von TBIZ beschränkt sich auf die Unterstützung bei
          konkreten Problemstellungen, welche sich bei der Erhaltung der
          Einsatzfähigkeit der unterstützten Software und der vom Kunden
          angegebenen Systeme ergeben.
          <br />
          (8) Die vertragsgegenständlichen Leistungen von TBIZ sind weiterhin
          beschränkt auf die Unterstützung bei konkreten Problemstellungen mit
          den Diensten, Programmen und Paketen, die sich aus der
          Leistungsbeschreibung des Supportvertrages ergeben.
          <br />
          (9) Ausgenommen von der Leistung von TBIZ sind ferner jegliche
          Programmierarbeiten, die über das zur Bearbeitung der Problemstellung
          und Systemerhaltung notwendige Maß hinausgehen, sowie die Entwicklung
          komplexer IT-Konzepte, Machbarkeitsstudien und Ähnlichem (Consulting).
          Ebenso ausgenommen ist die exklusive Entwicklung und Bereitstellung
          spezifischer Softwareanpassungen (Patches) für den Auftraggeber.
          <br />
          (10) TBIZ ist berechtigt, die Supportleistungen für den Kunden
          unmittelbar durch eigene Mitarbeiter oder mittelbar durch Beauftragte
          von TBIZ zu erbringen.
        </Text>
      </Block>
      <Block>
        <Headings>7. Supportlevel und Reaktionszeit</Headings>
        <Text>
          (1) TBIZ erbringt seine Support-Leistungen zu folgenden Zeiten: Montag
          bis Freitag von 9:00 bis 16:00 Uhr deutscher Zeit, nicht an
          deutschlandweiten und hessischen Feiertagen.
          <br />
          (2) Reaktionszeit ist die Zeitspanne, die zwischen dem Eingang der
          Anfrage, unterbrochen durch Zeiten außerhalb des vereinbarten
          Supportlevels und der Reaktion durch TBIZ liegt.
          <br />
          (3) Die Reaktionszeiten werden individuell vereinbart und sind im
          Angebot aufgeführt. Im Standardfall ist unsere Reaktionszeit am
          nächsten Werktag.
          <br />
          (4) Wenn im Vertrag ein Supportkontingent vereinbart wurde, gelten
          nachfolgende Regelungen:
          <br />
          (a) Für die Bearbeitungen der Anfragen des Kunden ist in der
          Leistungsbeschreibung des Vertrages ein Kontingent für einen
          kalendermäßigen Zeitraum festgelegt, auf das die maximale
          Bearbeitungsdauer beschränkt ist.
          <br />
          (b) Ist das Kontingent für den aktuellen Zeitraum aufgebraucht, werden
          weitergehende Leistungen in diesem Zeitraum zusätzlich nach dem
          vereinbarten Stundensatz abgerechnet.
          <br />
          (c) Nicht aufgebrauchte Zeiteinheiten verfallen ersatzlos zum Ende des
          aktuellen Zeitraums und können nicht in den nächsten Zeitraum
          übertragen, erstattet oder verrechnet werden.
        </Text>
      </Block>
      <Block>
        <Headings>8. Leistungstermin</Headings>
        <Text>
          (1) Die Einhaltung der vereinbarten Reaktionszeit setzt den
          rechtzeitigen Eingang sämtlicher vom Kunden zu liefernden Unterlagen
          sowie die Einhaltung der vereinbarten Zahlungsbedingungen und
          sonstigen Verpflichtungen durch den Kunden voraus. Werden diese
          Voraussetzungen nicht rechtzeitig erfüllt, so verlängern sich die
          Fristen angemessen; dies gilt nicht, wenn TBIZ die Verzögerung zu
          vertreten hat.
          <br />
          (2) Ist die Nichteinhaltung der Fristen auf höhere Gewalt, z.B.
          Mobilmachung, Krieg, Aufruhr, oder auf ähnliche Ereignisse, z.B.
          Streik, Aussperrung, behördliche Eingriffe, Energie - und
          Rohstoffknappheit, Transportengpässe, unverschuldete
          Betriebsbehinderungen, z. B. durch Ausfall der
          Elektrizitätsversorgung, Störungen im Internet, Feuer, Wasser,
          Elektronikschäden, Ausfall von Telekommunikationsnetzen oder Rechnern
          und Maschinenschäden zurückzuführen, verlängern sich die Fristen
          angemessen.
        </Text>
      </Block>
      <Block>
        <Headings>9. Vergütung</Headings>
        <Text>
          (1) TBIZ ist berechtigt, Abschlagsrechnungen für erbrachte Tätigkeiten
          zu legen. Sofern kein anderer Zahlungsplan vereinbart wurde, kann TBIZ
          nach eigenem Ermessen den Abrechnungszeitraum festlegen und den
          zwischenzeitlich entstandenen Zeitaufwand und sonstige erbrachte
          Leistungen auf Grundlage der Tätigkeitsnachweise abrechnen, ohne dass
          dafür ein bestimmter Leistungsstand nachgewiesen werden muss. Spesen
          und Fahrtkosten können zum Ultimo eines jeden Monats nach deren
          Entstehung abgerechnet werden. Der Kunde verzichtet auf Einwendungen
          hinsichtlich der abgerechneten Leistungen, sofern diese auf
          anerkannten Leistungsnachweisen beruhen.
          <br />
          (2) TBIZ ist berechtigt, die Vergütung bei Dauerschuldverhältnissen
          für die von ihr angebotenen Leistungen erstmalig 6 Monate nach
          Abschluss des Vertrages zu erhöhen. Die Erhöhung ist an die von TBIZ
          aufgrund der allgemeinen Preisentwicklung entstehende Kostensteigerung
          anzupassen. Sie wird einen Monat nach ihrer Mitteilung wirksam. Der
          Kunde kann für den Zeitpunkt des Wirksamwerdens außerordentlich
          kündigen. TBIZ weist den Kunden auf dieses Kündigungsrecht hin.
          <br />
          (3) Zahlungen sind 8 Tage nach Rechnungserstellung ohne jeden Abzug
          fällig. TBIZ legt die Rechnungen in digitaler Form im PDF-Format und
          übersendet diese dem Kunden per E-Mail an die vertraglich festgelegte
          Adresse. Eine zusätzliche Übersendung per Post ist ausdrücklich zu
          vereinbaren. Überschreitet der Kunde die eingeräumten Zahlungsfristen,
          so werden ohne weitere Mahnung ab Eintritt der Fälligkeit Zinsen in
          Höhe von 9% p.a. über dem jeweils gültigen Basiszinssatz der
          Europäischen Zentralbank auf den Kaufpreis geschuldet. Das Recht zur
          Geltendmachung eines darüber hinausgehenden Schadens bleibt unberührt.
          <br />
          (4) Das vom Kunden zu zahlende Entgelt bei Hosting und Managed Service
          Verträgen ist monatlich im Voraus fällig, zahlbar ohne Abzug von
          Skonto, netto Kasse. Ausnahmen davon sind explizit im Angebot
          aufgeführt.
          <br />
          (5) Das vom Kunden zu zahlende Entgelt bei Schulungen und
          Veranstaltungen ist mit Vertragsschluss fällig, zahlbar ohne Abzug von
          Skonto, netto Kasse. Ausnahmen davon sind explizit im Angebot
          aufgeführt.
          <br />
          (6) Die Kosten für Leistungen, die über vereinbarte Pauschalleistungen
          hinaus in Anspruch genommen werden, bemessen sich nach den jeweils bei
          Inanspruchnahme gültigen TBIZ Preisen.
          <br />
          (7) TBIZ ist berechtigt, trotz anderslautender Bestimmungen des
          Kunden, Zahlungen zunächst auf dessen ältere Schulden anzurechnen.
          Sind bereits Kosten und Zinsen durch Verzug entstanden, ist TBIZ
          berechtigt, die Zahlungen zunächst auf die Kosten, dann auf die Zinsen
          und zuletzt auf Hauptleistungen anzurechnen.
          <br />
          (8) Der Kunde kann nur mit unbestrittenen oder rechtskräftig
          festgestellten Forderungen aufrechnen. Ein Zurückbehaltungsrecht kann
          der Kunde nur wegen Gegenforderungen ausüben, die auf demselben
          Vertragsverhältnis beruhen. Bei laufenden Geschäftsbeziehungen gilt
          jede einzelne Bestellung als gesondertes Vertragsverhältnis.
          <br />
          (9) Wird von den Zahlungsbedingungen ohne rechtfertigenden Grund
          abgewichen, kann TBIZ jederzeit wahlweise Lieferung Zug um Zug gegen
          Barzahlung, Vorkasse oder Sicherheitsleistung verlangen. Alle offenen
          Forderungen, einschließlich derjenigen, für die andere
          Zahlungsbedingungen vereinbart wurden, werden zur sofortigen Zahlung
          fällig.
          <br />
          (10) Mit TBIZ vereinbarte Termine können spätestens 1 Woche vor dem
          Termin vom Kunden abgesagt werden.
        </Text>
      </Block>
      <Block>
        <Headings>10. Vertragslaufzeit und Kündigung</Headings>
        <Text>
          (1) Verträge können von beiden Parteien mit einer Frist von 3 Monaten
          zum Vertragsende gekündigt werden. Die Mindestvertragslaufzeit
          beträgt, sofern nichts Gegenteiliges vereinbart wurde, 2 Jahre. Wird
          der Vertrag nicht fristgemäß gekündigt, verlängert sich der Vertrag um
          ein weiteres Jahr.
          <br />
          (2) Bei erheblichen Verstößen gegen die vertraglichen Pflichten einer
          Vertragspartei, ist die jeweils andere Vertragspartei zur sofortigen
          Kündigung berechtigt. Ein beidseitig zur außerordentlichen Kündigung
          berechtigender Grund liegt insbesondere vor, wenn das
          Insolvenzverfahren gegen eine Vertragspartei eröffnet wird, dessen
          Eröffnung mangels Masse abgelehnt wird oder
          Zwangsvollstreckungsmaßnahmen eingeleitet werden.
          <br />
          (3) Bei Beendigung des Vertrages werden beide Parteien alle ihr mit
          Vertragsunterzeichnung übergebenen Unterlagen zurückgeben bzw.
          nachweisen, dass diese Unterlagen ordnungsgemäß vernichtet wurden.
          Vorhandene Datenbestände sind physikalisch zu löschen.
          <br />
          (4) Nach Beendigung einer sogenannten Softwarepflege können keine
          kostenlosen Updates für die verbundene Anwendung / Komponente mehr
          bezogen werden. In Verbindung mit einem der Softwarepflege
          vorangegangenen Lizenzkauf ist die Nutzung im Rahmen und Umfang der
          zum Zeitpunkt der Beendigung bestehenden Konfigurationen, soweit nicht
          anders schriftlich vereinbart, weiterhin möglich.
        </Text>
      </Block>
      <Block>
        <Headings>11. Eigentumsvorbehalt</Headings>
        <Text>
          (1) Die gelieferten Produkte bleiben Eigentum von TBIZ bis zur
          Erfüllung aller, auch zukünftiger Forderungen aus dem Vertrag und
          darüber hinaus aus der gesamten Geschäftsbeziehung mit dem Kunden.
          <br />
          (2) Der Kunde ist zur Weiterveräußerung der Vorbehaltsware im
          ordnungsgemäßen Geschäftsverkehr unter Eigentumsvorbehalt berechtigt.
          Seine künftigen Forderungen aus der Weitergabe der Vorbehaltsware
          tritt der Kunde hiermit im jeweiligen Rechnungswert bis zur
          vollständigen Bezahlung sämtlicher Ansprüche zur Sicherheit an TBIZ
          ab. TBIZ darf zur Sicherung seiner Zahlungsansprüche jederzeit diese
          Abtretung offenlegen. Auf Verlangen von TBIZ wird der Kunde TBIZ Namen
          und Anschrift seiner betroffenen Abnehmer sowie Art und Umfang seiner
          gegen diese bestehenden Ansprüche mitteilen.
          <br />
          (3) Eine Verpfändung oder Sicherungsübereignung von Vorbehaltsware ist
          dem Kunden nicht erlaubt. Bei Zugriffen Dritter auf die Vorbehaltsware
          wird der Kunde auf das Eigentum von TBIZ hinweisen und TBIZ
          unverzüglich schriftlich benachrichtigen.
          <br />
          (4) Eine Verbindung, Vermischung, Verarbeitung oder Umbildung der
          Vorbehaltsware erfolgt ausschließlich für TBIZ. In diesem Falle
          erwirbt TBIZ einen Miteigentumsanteil an der fertigen Ware bzw. an der
          neuen Sache, der dem Verhältnis des Wertes der Vorbehaltsware zum Wert
          der fertigen Ware bzw. der neuen Sache entspricht.
          <br />
          (5) Bei Zahlungsverzug, auch aus anderen und zukünftigen Lieferungen
          oder Leistungen von TBIZ an den Kunden, oder bei Anhaltspunkten für
          eine Verschlechterung der Vermögensverhältnisse des Kunden, darf TBIZ
          zur Geltendmachung des Eigentumsvorbehaltes die Geschäftsräume des
          Kunden betreten und die Vorbehaltsware an sich nehmen bzw. die
          Abtretung von Herausgabeansprüchen des Kunden gegen seine Abnehmer
          verlangen.
          <br />
          (6) Die Rücknahme oder Pfändung der Vorbehaltsware durch TBIZ gilt
          nicht als Vertragsrücktritt, sofern der Kunde Kaufmann ist.
          <br />
          (7) Auf Verlangen des Kunden wird TBIZ Sicherheiten insoweit
          freigeben, als ihr Wert die zu sichernden Forderungen insgesamt um
          mehr als 10 % übersteigt.
          <br />
          (8) Für Test- und Vorführzwecke gelieferte Gegenstände bleiben im
          Eigentum von TBIZ. Der Kunde ist verpflichtet, für eine ordnungsgemäße
          Aufbewahrung Sorge zu tragen und darf die Gegenstände nur aufgrund
          gesonderter Vereinbarung mit TBIZ über den Test und Vorführzweck
          hinaus benutzen. Etwaige Kosten für die Nutzung während und nach
          Beendigung des Testzeitraums werden gesondert im Angebot ausgewiesen.
        </Text>
      </Block>
      <Block>
        <Headings>12. Pfandrecht</Headings>
        <Text>
          (1) Hat der Kunde ein sonstiges Recht an einer Sache, die in die Räume
          von TBIZ eingebracht wird, insbesondere ein Anwartschaftsrecht, so
          tritt er dieses zur Sicherung der Forderungen von TBIZ gegenüber dem
          Kunden bereits jetzt an TBIZ ab.
        </Text>
      </Block>
      <Block>
        <Headings>13. Gewährleistung</Headings>
        <Text>
          (1) TBIZ gewährleistet, dass die Vertragsprodukte nicht mit
          wesentlichen Mängeln behaftet sind und für die nach dem Vertrag
          vorausgesetzte Verwendung geeignet sind bzw. sich für die gewöhnliche
          Verwendung eignen. Dabei sind sich die Vertragspartner bewusst, dass
          es nach dem Stand der Technik nicht möglich ist, Fehler der Software
          unter allen Anwendungsbedingungen auszuschließen.
          <br />
          (2) TBIZ übernimmt keine Gewähr dafür, dass die Funktionen von
          Software den Anforderungen des Kunden genügen und die Vertragsprodukte
          in der vom Kunden getroffenen Auswahl zusammenarbeiten.
          <br />
          (3) Sachmängelansprüche bestehen nicht bei nur unerheblicher
          Abweichung von der vereinbarten Beschaffenheit oder bei nur
          unerheblicher Beeinträchtigung der Brauchbarkeit oder wenn das Produkt
          durch den Kunden oder Dritte verändert, unsachgemäß installiert,
          gewartet, repariert, benutzt oder Umgebungsbedingungen ausgesetzt
          wird, die nicht den Installationsanforderungen der Hersteller
          entsprechen. Eine Haftung für Sachmängel besteht nur, sofern die
          Ursache des Sachmangels bereits zum Zeitpunkt des Gefahrenübergangs
          vorlag.
          <br />
          (4) Falls keine abweichende individuelle Regelung getroffen worden
          ist, verjähren Sachmängelansprüche nach 12 Monaten und die Verjährung
          beginnt mit Ablieferung. Sachmängelhaftungsansprüche sind nur mit
          Zustimmung von TBIZ übertragbar. Weitergehende Garantie und
          Gewährleistungszusagen der Hersteller gibt TBIZ in vollem Umfang an
          den Kunden weiter, ohne dafür selbst einzustehen.
          <br />
          (5) Bei Vorliegen eines Sachmangels erfolgt nach Wahl von TBIZ
          zunächst Nachbesserung oder Ersatzlieferung. Ersetzte Teile gehen in
          das Eigentum von TBIZ über. Ist TBIZ zur Nachbesserung oder
          Ersatzlieferung nicht in der Lage, ist dies mit unverhältnismäßigen
          Kosten verbunden oder beseitigt TBIZ Mängel nicht innerhalb einer
          angemessenen, schriftlich gesetzten Nachfrist, ist der Kunde zur
          Minderung des Kaufpreises oder zum Rücktritt vom Kaufvertrag
          berechtigt. Liefert TBIZ zum Zwecke der Nacherfüllung ein
          Ersatzprodukt, hat der Kunde das mangelhafte Produkt herauszugeben und
          Wertersatz für Gebrauchsvorteile zu leisten. Im Falle des Rücktritts
          wird dem Kunden ein Betrag gutgeschrieben, der sich aus dem Kaufpreis
          abzüglich der wertmäßigen Gebrauchsvorteile ergibt. Für die Ermittlung
          der Gebrauchsvorteile wird auf das Verhältnis der Nutzung des
          Gegenstandes durch den Käufer zur voraussichtlichen
          Gesamtnutzungsdauer abgestellt.
          <br />
          (6) Alle mit der Nachbesserung oder Ersatzlieferung verbundenen
          Nebenkosten (z. B. Transportkosten, Verpackungskosten) trägt der
          Kunde, es sei denn, dass sie zum Auftragswert außer Verhältnis stehen.
          <br />
          (7) Ergibt die Überprüfung einer Mängelanzeige, dass ein Sachmangel
          nicht vorliegt, werden die Kosten der Überprüfung und Reparatur zu den
          jeweils gültigen Verrechnungssätzen von TBIZ berechnet.
          <br />
          (8) Bei Inanspruchnahme der Gewährleistung, kostenpflichtigen
          Reparaturaufträgen und Retouren jeglicher Art gelten die jeweils
          aktuellen Abwicklungsrichtlinien und die jeweils aktuelle Preisliste.
        </Text>
      </Block>
      <Block>
        <Headings>14. Haftung</Headings>
        <Text>
          (1) TBIZ haftet für Vorsatz und grobe Fahrlässigkeit nach den
          gesetzlichen Vorschriften. Für leichte Fahrlässigkeit haftet TBIZ nur
          bei Verletzung einer wesentlichen Vertragspflicht (Kardinalpflicht)
          sowie bei Schäden aus der Verletzung des Lebens, des Körpers oder der
          Gesundheit. TBIZ haftet dabei nur für vorhersehbare Schäden, mit deren
          Entstehung typischerweise gerechnet werden muss. Der Anwendungsbereich
          des § 44a TKG bleibt unberührt.
          <br />
          (2) Ist die Haftung von TBIZ ausgeschlossen oder begrenzt, gilt dies
          auch für die persönliche Haftung der Angestellten, Arbeitnehmer,
          Mitarbeiter, Vertreter und Erfüllungsgehilfen.
          <br />
          (3) Die Haftung für einen von TBIZ zu vertretenden Verlust von Daten
          oder Programmen ist zudem auf den Schaden begrenzt, der eingetreten
          ist bzw. wäre, wenn der Kunde seine Daten innerhalb angemessener
          Intervalle gesichert hat bzw. hätte (Backup).
          <br />
          (4) Die Haftung ist im Falle leichter Fahrlässigkeit begrenzt auf die
          Deckungssumme der von TBIZ abgeschlossenen Betriebs- und
          Produkthaftpflichtversicherung. TBIZ ist im Einzelfall bereit, die
          entsprechende Deckungssumme dem Kunden mitzuteilen.
        </Text>
      </Block>
      <Block>
        <Headings>15. Allgemeine Bestimmungen</Headings>
        <Text>
          (1) Der Kunde ist nicht berechtigt, seine Ansprüche aus dem Vertrag
          abzutreten.
          <br />
          (2) TBIZ darf auf die Geschäftsbeziehung mit dem Kunden im Rahmen von
          Referenzen hinweisen und diese zu werblichen Zwecken nutzen, sofern
          nichts Gegenteiliges vereinbart wurde.
          <br />
          (3) Ausschließlicher Gerichtsstand für alle Streitigkeiten aus diesem
          Vertragsverhältnis ist - soweit gesetzlich zulässig - der Sitz des
          Anbieters, derzeit in Marburg/Lahn. Der Anbieter ist allerdings
          berechtigt, am Hauptsitz des Kunden zu klagen.
          <br />
          (4) Die Parteien verpflichten sich gegenseitig, keine fest
          angestellten oder freien Mitarbeiter des anderen Vertragspartners,
          auch bis 2 Jahre nach Beendigung eines Vertragsverhältnisses,
          abzuwerben, anzustellen oder in eigenen Dienst- oder
          Werkvertragsverhältnissen zu beschäftigen. Bei Zuwiderhandlung
          verpflichten sich die Vertragspartner zur Zahlung einer Vertragsstrafe
          in Höhe von 15.000 EUR für jeden Fall der Zuwiderhandlung an den
          anderen Vertragspartner. Die Vertragsstrafe wird nicht fällig, wenn
          der andere Vertragspartner schriftlich einem solchen
          Vertragsverhältnis zustimmt.
          <br />
          (5) Auf vorliegenden Vertrag findet deutsches Recht unter Ausschluss
          des UN-Kaufrechts Anwendung.
          <br />
          (6) Der Kunde wird nach den Bestimmungen des Bundesdatenschutzgesetzes
          darauf hingewiesen, dass seine für die Abwicklung der geschäftlichen
          Beziehungen erforderlichen firmen-, bzw. personenbezogenen Daten mit
          Hilfe der EDV verarbeitet werden.
          <br />
          (7) Ein Kunde mit Sitz außerhalb Deutschlands hat die Regelungen der
          Einfuhrumsatzsteuer der Europäischen Union zu beachten, insbesondere
          unaufgefordert die Umsatzsteueridentifikationsnummer an TBIZ bekannt
          zu geben und bereitwillig notwendige Auskünfte zu erteilen. Bei
          Missachtung hat der Kunde TBIZ den dadurch entstehenden Aufwand zu
          erstatten.
          <br />
          (8) Mündliche Nebenabreden sind nicht getroffen. Änderungen,
          Ergänzungen und Zusätze dieses Vertrages haben nur Gültigkeit, wenn
          sie zwischen den Vertragsparteien schriftlich vereinbart werden. Dies
          gilt auch für die Abänderung dieser Vertragsbestimmung.
          <br />
          (9) Sollte eine Bestimmung dieses Vertrages unwirksam sein oder
          werden, so berührt dies die Wirksamkeit des Vertrages im Übrigen
          nicht. Die unwirksame Bestimmung gilt als durch eine wirksame Regelung
          ersetzt, die dem wirtschaftlichen Zweck der unwirksamen Bestimmung am
          nächsten kommt. Entsprechendes gilt im Fall einer Vertragslücke.
          <br />
          (10) TBIZ behält sich vor, diese Regelungen regelmäßig zu überprüfen
          und, soweit gesetzlich erforderlich, anzupassen.
        </Text>
      </Block>
      <Stamp>Stand: 01.03.2024</Stamp>
    </>
  ),
};
