import { PageHead } from './PageHead';
import placeholder from '../../images/tbiz-article-placeholder.png';
import React from 'react';

type Props = {
  title: string;
  description?: string;
  image?: string;
  children?: React.ReactNode;
};

export const Seo: React.FC<Props> = ({
  title,
  image,
  description,
  children,
}) => {
  const imageOgMeta = image ? image : placeholder;

  return (
    <PageHead title={title}>
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />
      {description && <meta property="og:description" content={description} />},
      {description && (
        <meta property="twitter:description" content={description} />
      )}
      ,
      <meta property="og:image" itemProp="image" content={imageOgMeta} />,
      <meta property="twitter:image" itemProp="image" content={imageOgMeta} />,
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      {children}
    </PageHead>
  );
};
