import { ReactNode } from 'react';

export type TranslationsObject = Record<string, ReactNode>;

export const useTranslationsObject = (
  translationObject: TranslationsObject,
  language: string,
  defaultLanguage: string
) => {
  return translationObject[language] || translationObject[defaultLanguage];
};
